import { AxiosError } from "axios";
import api from "../../shared/request";
import { EmployeeInfo } from "./employeeSlice";

const GET_EMPLOYEE_INFO_ENDPOINT = "employee";

export const getEmployeeInfoRequest = async () => {
    try {
        const response = await api.get<EmployeeInfo>(GET_EMPLOYEE_INFO_ENDPOINT);
        return response.data;
    } catch (error: any | Error | AxiosError) {
        throw error.response.data.errorDescription;
    }
}