import api from "../../shared/request";


const REGISTER_ENDPOINT = 'authentication/v2/register';

export const registerRequset = async (
    email: string,
    name: string,
    lastName: string,
    rightsFlags: string,
) => {
    return api.post(
        REGISTER_ENDPOINT,
        {
            email, name, lastName, rightsFlags
        }
    )
        .then(res => {
            return res.data;
        })
        .catch(error => {
            throw mapErrorCodeToRuMessage(
                error.response.data
            )
        })
}

function mapErrorCodeToRuMessage(error: { errorCode: number, errorDescription: string }) {
    switch (error.errorCode) {
        case 4091:
            const email = error.errorDescription.split(' ').filter(word => word.includes('@'))[0];
            return `Пользователь с email ${email} уже зарегистрирован.`;
        default:
            return error.errorDescription ? error.errorDescription : "Что-то пошло не так:(.";
    }
}