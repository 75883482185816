import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { router } from './appRouter';
import { useCheckAuth } from '../features/authentication/useCheckAuth';

function App() {
  useCheckAuth();

  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
