import { createAsyncThunk, createSlice, createAction, Action, AnyAction } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../App/store';
import { changePasswordRequest, checkAuthRequest, loginRequest } from './authService';
import { LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN } from '../../shared/request';


interface SessionState {
    isAuth: boolean,
    accessToken: string,
    isPasswordChanged: boolean
}

export interface AuthCredentials {
    email: string,
    password: string,
}

const initialState: SessionState = {
    isAuth: false,
    accessToken: '',
    isPasswordChanged: true,
}

export const login = createAsyncThunk(
    'session/login',
    async (params: AuthCredentials, thunkAPI) => {
        try {
            const response = await loginRequest(params.email, params.password);
            if (response && response.accessToken) {
                localStorage.setItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN, response.accessToken);
                return response.accessToken;
            } else {
                return thunkAPI.rejectWithValue(response);
            }
        } catch (e) {
            // e -- error message.
            return thunkAPI.rejectWithValue(e);
        }
    }
)

export const checkAuth = createAsyncThunk(
    'session/checkAuth',
    async (params, thunkAPI) => {
        try {
            const response = await checkAuthRequest();
            if (response && response.accessToken) {
                localStorage.setItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN, response.accessToken);
                return response.accessToken;
            } else {
                localStorage.removeItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN);
                return thunkAPI.rejectWithValue('The session is over.')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

export const logout = createAsyncThunk(
    'session/logout',
    async (params, thunkAPI) => {
        localStorage.removeItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN);
    }
)

export const changePassword = createAsyncThunk(
    'session/changePassword',
    async ({ password }: { password: string }, thunkAPI) => {
        try {
            const response = await changePasswordRequest(password);
            return response.data;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setPasswordWasNotChanged(state) {
            state.isPasswordChanged = false;
        },
    },
    extraReducers: builder =>
        builder
            .addCase(
                login.fulfilled, (state, action: PayloadAction<string>) => {
                    state.isAuth = true;
                    state.accessToken = action.payload;
                }
            )
            .addCase(
                login.rejected, (state, action) => {
                    state.isAuth = false;
                    state.accessToken = '';
                }
            )
            .addCase(
                checkAuth.fulfilled, (state, action: PayloadAction<string>) => {
                    state.isAuth = true;
                    state.accessToken = action.payload;
                }
            )
            .addCase(
                checkAuth.rejected, (state, action) => {
                    state.isAuth = false;
                    state.accessToken = '';
                }
            )
            .addCase(
                logout.fulfilled, (state, action) => {
                    state.isAuth = false;
                    state.accessToken = '';
                }
            )
            .addCase(
                changePassword.fulfilled, (state, action) => {
                    state.isPasswordChanged = true;
                }
            )
})

export const isAuthorized = (state: RootState) => state.auth.isAuth;
export const { setPasswordWasNotChanged } = sessionSlice.actions;
export default sessionSlice.reducer;