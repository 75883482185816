import { EmployeeInfoContainer } from "../features/employee/EmployeeInfo";
import { ProfileSettingsButton } from "../features/settings/ProfileSettingsButton";


export function Header() {
    return (
        <header>
            <h2>DealDone Admin</h2>
            <div
                style={{
                    'display': 'flex',
                    'alignItems': 'center'
                }}
            >
                <EmployeeInfoContainer />
                <ProfileSettingsButton />
            </div>
        </header>
    )
}