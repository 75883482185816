import { Navigate } from "react-router"
import { isAuthorized } from "./authSlice"
import { useAppSelector } from "../../App/hooks"

export function AuthGateway({ children }: React.PropsWithChildren) {
    const isAuth = useAppSelector(isAuthorized);

    if (!isAuth) {
        return <Navigate to='/login' replace={true} />
    }

    return (
        <>
            {children}
        </>
    )
}