import { baseQueryWithReauth } from "../../shared/baseQuery";
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { FailedDeal } from "./failedDeal.dto";

export const dealsAPI = createApi({
    reducerPath: 'adminDealApi',
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({

        fetchFailedDeals: builder.query<FailedDeal[], unknown>({
            query: () => ({
                url: 'FailedDeals',
                method: 'GET'
            })
        }),

        reprocessDeal: builder.mutation<unknown, number>({
            query: (messageId: number) => ({
                url: `FailedDeal/${messageId}/reprocess`,
                method: 'POST'
            })
        })

    })
})

export const useFetchFailedDeals = dealsAPI.endpoints.fetchFailedDeals.useQuery;
export const useReprocessDeal = dealsAPI.endpoints.reprocessDeal.useMutation;