import React from 'react';
import { useAppDispatch } from "../../App/hooks";
import { LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN } from "../../shared/request"
import { useRequest } from "../../shared/useRequest";
import { checkAuth } from "./authSlice";


export const useCheckAuth = () => {
    const dispatch = useAppDispatch();

    const { sendRequest, isLoading, response, error } = useRequest(
        () => dispatch(checkAuth())
    );

    React.useEffect(() => {
        try {
            if (localStorage.getItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN)) {
                sendRequest();
            }
        } catch (e) {
            console.log(e);
        }
    }, []);
}