import './ErrorMessage.css';

type ErrorProps = {
    text: string
}

export function ErrorMessage({ text }: ErrorProps) {
    if (!text) {
        return null;
    }

    return (
        <p className="Error">
            {text}
        </p>
    )

}