import { createAsyncThunk } from "@reduxjs/toolkit";
import { uploadInstrumentRequest } from "./instrumentInfoService";

export async function sendInstrumentInfoUploadRequest(file: File) {
    try {
        const response = await uploadInstrumentRequest(file);
        return response;
    } catch (e) {
        return {
            error: {
                payload: e,
            }
        };
    }
}

export const sendInstrumentInfo = createAsyncThunk(
    'instrument/infoSend',
    async (file: File, thunkAPI) => {
        try {
            const response = await uploadInstrumentRequest(file);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)