import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEmployeeInfoRequest } from "./employeeService";

export interface EmployeeInfo {
    name: string,
    lastName?: string
}

const initialState: EmployeeInfo = {
    name: '',
    lastName: ''
}

export const getEmployeeInfo = createAsyncThunk(
    'broker/getInfo',
    async (params, thunkAPI) => {
        try {
            const response = await getEmployeeInfoRequest();
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

export const brokerSlice = createSlice({
    name: 'broker',
    initialState,
    reducers: {},
    extraReducers: builder =>
        builder
            .addCase(
                getEmployeeInfo.fulfilled, (state, action: PayloadAction<EmployeeInfo>) => {
                    state.name = action.payload.name;
                    state.lastName = action.payload.lastName;
                }
            )
})

export default brokerSlice.reducer;