import { useState } from "react";

type ActionToRequest = (params: any) => Promise<any>

export const useRequest = (action: ActionToRequest) => {
    const [isLoading, setIsLoading] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState<string | object>('');

    function sendRequest(params?: any) {
        setIsLoading(true);
        action(params)
            .then((res: any) => {
                setResponse(res);
                if (res && res.error) {
                    setError(res.payload);
                } else {
                    setError('');
                }
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    return { sendRequest, isLoading, response, error };
}