import axios from "axios";
import { AnyAction, AsyncThunkAction, PayloadAction, Store, ThunkAction } from "@reduxjs/toolkit";
import { checkAuth } from "../features/authentication/authSlice";
import { AppDispatch, RootState } from "../App/store";
import CONFIG from './../appConfig.json';

export const API_URL = CONFIG.serverAddress;

export const LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN = 'token-admin'

const api = axios.create({
    withCredentials: true,
    baseURL: API_URL,
});

api.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${localStorage.getItem(LOCALSTORAGE_KEY_FOR_ACCESS_TOKEN)}`
    return config;
});


let store: Store<RootState>;

export const injectStore = (_store: Store)  => {
    store = _store;
}

api.interceptors.response.use(config => {
    return config;
}, async error => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && error.config && !error.config._isRetry) {
        originalRequest._isRetry = true;
        try {
            const response = await store.dispatch<any>(checkAuth());
            return api.request(originalRequest);
        } catch (e) {
        }
    }
    throw error
})

export default api;