import React from "react";
import { useAppDispatch, useAppSelector } from "../../App/hooks";
import { EmployeeInfo, getEmployeeInfo } from "./employeeSlice";

export function EmployeeInfoContainer() {
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(getEmployeeInfo());
    }, [])

    const employeeInfo = useAppSelector(state => state.employee);

    return (
        <EmployeeInfoPresentation
            name={employeeInfo.name}
            lastName={employeeInfo.lastName}
        />
    )
}

export function EmployeeInfoPresentation({ lastName, name }: EmployeeInfo) {
    return (
        <div>{name} {lastName}</div>
    )
}