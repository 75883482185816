import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { registerRequset } from "./usersService"

interface Users {
    users: []
}

interface RegisterCredentials {
    email: string,
    name: string,
    lastName: string,
    rightsFlags: string,
}

export const initialState = {

}

export const register = createAsyncThunk(
    'users/register',
    async (params: RegisterCredentials, thunkAPI) => {
        try {
            const response = await registerRequset(params.email, params.name, params.lastName, params.rightsFlags);
            return response;
        } catch (e) {
            return thunkAPI.rejectWithValue(e);
        }
    }
)

// export const userSlice = createSlice({
//     name: 'user',
//     initialState,
// })