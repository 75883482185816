import React from "react";

import { Button } from "@mui/material"
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';

import './InstrumentInfoUploadButton.css';
import { useRequest } from "../../shared/useRequest";
import { LoadingButton } from "@mui/lab";
import DoneIcon from '@mui/icons-material/Done';
import { uploadInstrumentRequest } from "./instrumentInfoService";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { sendInstrumentInfo, sendInstrumentInfoUploadRequest } from "./instrumentInfoInteraction";
import { useAppDispatch } from "../../App/hooks";


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


export function InstrumentInfoUploadButton() {
    const [file, setFile] = React.useState<File>();

    const sendFile = async function (e: React.FormEvent) {
        e.preventDefault();
        sendRequest();
    }


    const dispatch = useAppDispatch();
    const { sendRequest, response, isLoading, error } = useRequest(async () => {
        if (!file) {
            return;
        }

        return dispatch(sendInstrumentInfo(file));
    });

    return (
        <form onSubmit={sendFile} className="InstrumentUploadForm">
            <Button
                component='label'
                variant="outlined"
            >
                Загрузить инструменты
                <VisuallyHiddenInput
                    name='file'
                    type='file'
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files) {
                            setFile(e.target.files[0]);
                        }
                    }}
                    accept=".xlsx"
                />
            </Button>

            <p>{file ? file.name : ''}</p>

            <LoadingButton
                variant="contained"

                loading={isLoading}
                loadingPosition='start'

                startIcon={response && !error ? <DoneIcon /> : <CloudUploadIcon />}
                type="submit"
            >
                Отправить
            </LoadingButton>
            <ErrorMessage text={`${error}`} />
        </form>
    )
}