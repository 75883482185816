import { CircularProgress } from "@mui/material";
import { useFetchFailedDeals } from "./deals.slice";
import { FailedDealsTable } from "./FailedDealsTable";

export function FailedDealsWidget() {
    const { data, isLoading, error } = useFetchFailedDeals({});

    return (
        <div>
            <h3>Переотправить сделки</h3>
            {
                isLoading
                ? <CircularProgress />
                : null
            }
            {
                error
                ? <p>{JSON.stringify(error)}</p>
                : null
            }
            {
                data
                ? <FailedDealsTable deals={data} />
                : null
            }
        </div>
    )
}