import { createBrowserRouter } from "react-router-dom";
import { AuthGateway } from "../features/authentication/AuthGateway";
import { InstrumentInfoUploadButton } from "../features/instrumentsInfo/InstrumentInfoUploadButton";
import { RegisterUser } from "../features/users/RegisterUser";
import { Layout } from "../layout/Layout";
import { LoginPage } from "../features/authentication/LoginPage";
import { SettingsWidget } from "../features/settings/SettingsWidget";
import { FailedDealsWidget } from "../features/failedDeals/FailedDealsWidget";

export const router = createBrowserRouter([
    {
        path: '/login',
        element: <LoginPage />
    },
    {
        path: '/',
        element: <AuthGateway>
                    <Layout />
                </AuthGateway>,
        children: [
            {
                path: '/instrumentinfoupload',
                element: <InstrumentInfoUploadButton />
            },
            {
                path: '/brokers',
                element: <RegisterUser />
            },
            {
                path: '/settings',
                element: <SettingsWidget />
            },
            {
                path: '/restartdeals',
                element: <FailedDealsWidget />
            }
        ]
    }
]);