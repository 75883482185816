import { configureStore } from '@reduxjs/toolkit'
import authReducer from './../features/authentication/authSlice';
import employeeReducer from '../features/employee/employeeSlice';
import { dealsAPI } from '../features/failedDeals/deals.slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    employee: employeeReducer,
    [dealsAPI.reducerPath]: dealsAPI.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(dealsAPI.middleware)
});


export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;