import { Navigate } from "react-router";
import { useAppSelector } from "../../App/hooks";
import { LogInForm } from "./LogInForm";
import { isAuthorized } from "./authSlice";


export function LoginPage() {
    const isAuth = useAppSelector(isAuthorized);

    if (isAuth) {
        return <Navigate to='/' replace={true} />
    }

    return (
        <div
            style={{
                display: 'flex',
                'flexDirection': 'column',
                alignItems: 'center'
            }}
        >
            <h1>DealDone Admin App</h1>
            <h2>Log in</h2>
            <LogInForm />
        </div>
    )
}