import { CheckBox } from "@mui/icons-material";
import { IconButton, Menu, MenuItem, MenuList, Paper } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DropdownTreeSelect from "react-dropdown-tree-select";
import { TreeNode } from 'react-dropdown-tree-select';
import data from './options.json';
import './RegisterUser.css';
import { useState } from "react";


export const RIGHTS_FLAGS_DEFAULT = 'None';

export const RightFlagsPicker = (
    { onValuesChanged }: { onValuesChanged: (values: string) => void }
) => {

    const onChange = (currentNode: any, selectedNodes: any) => {
        const labels = selectedNodes.map((node: TreeNode) => node.value);
        let rights = labels.join();
        
        if (!rights) {
            rights = RIGHTS_FLAGS_DEFAULT;
        }

        onValuesChanged(rights);
    };

    return (
        <DropdownTreeSelect
            data={data}
            onChange={onChange}
            keepOpenOnSelect={true}
            className="mdl-demo"
        />
    )
}