import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { useReprocessDeal } from "./deals.slice";
import { getDateFormat, getTimeFormat } from "../../shared/timeFormat";
import { FailedDeal } from "./failedDeal.dto";
import { LoadingButton } from "@mui/lab";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CheckIcon from '@mui/icons-material/Check';
import { useMemo, useState } from "react";

export function FailedDealsTable({ deals }: { deals: FailedDeal[] }) {
    const [rowsCount, setRowsCount] = useState(20);
    const [page, setPage] = useState(0);

    const pagedDeals = useMemo(() => {
        return deals.slice(page * rowsCount, (page + 1) * rowsCount);
    }, [deals, rowsCount, page]);

    if (pagedDeals.length === 0 && page === 0) {
        return (<p>Все сделки обработались.</p>);
    }

    return (
        <TableContainer
            component={Paper}
            sx={{
                overflowX: 'auto',
                marginTop: '40px'
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Тип сделки</TableCell>
                        <TableCell>Дата</TableCell>
                        <TableCell>Время</TableCell>

                        <TableCell>Инструмент</TableCell>

                        <TableCell>Купил</TableCell>
                        <TableCell>Продал</TableCell>

                        <TableCell>Объем</TableCell>

                        <TableCell>Курс</TableCell>
                        <TableCell>First Rate</TableCell>
                        <TableCell>Дата валютирования</TableCell>
                        <TableCell>Контрагент</TableCell>
                        <TableCell>Причина ошибки</TableCell>
                        <TableCell>Переотправить</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        pagedDeals.map((deal) => (<FailedDealCard key={deal.messageId} deal={deal} />))
                    }
                </TableBody>
            </Table>
            <TablePagination
                count={deals.length}
                page={page}
                rowsPerPage={rowsCount}
                onPageChange={(e, page) => {
                    setPage(page);
                }}
                rowsPerPageOptions={[10, 20, 30]}
                onRowsPerPageChange={(e) => { setRowsCount(e.target?.value as unknown as number) }}
                style={{
                    'marginLeft': 'auto'
                }}
            />
        </TableContainer>
    )
}

export function FailedDealCard({ deal }: { deal: FailedDeal }) {
    const [baseCurrency, quotedCurrency] = (deal.failedDealInfo.instrument?.split('_')?.[0]).split('/');

    return (
        <TableRow>
            <TableCell>{deal.failedDealInfo.dealType}</TableCell>
            <TableCell>{getDateFormat(new Date(deal.failedDealInfo.dealDateTime))}</TableCell>
            <TableCell>{getTimeFormat(new Date(deal.failedDealInfo.dealDateTime))}</TableCell>

            <TableCell>{deal.failedDealInfo.instrument}</TableCell>

            <TableCell>{deal.failedDealInfo.buyerCode}</TableCell>
            <TableCell>{deal.failedDealInfo.sellerCode}</TableCell>

            <TableCell>{Number(deal.failedDealInfo.value).toLocaleString('ru')}</TableCell>

            <TableCell>{Number(deal.failedDealInfo.rate).toLocaleString('ru', { minimumFractionDigits: 4 })}</TableCell>
            <TableCell>
                {
                    deal.failedDealInfo.firstRate
                        ? Number(deal.failedDealInfo.firstRate).toLocaleString('ru', { minimumFractionDigits: 4 })
                        : '--'
                }
            </TableCell>
            <TableCell>
                {
                    deal.failedDealInfo.valueDateTimeBase === deal.failedDealInfo.valueDateTimeQuoted
                        ? getDateFormat(new Date(deal.failedDealInfo.valueDateTimeBase))
                        : (`${baseCurrency} ${getDateFormat(new Date(deal.failedDealInfo.valueDateTimeBase))}\n
                        ${quotedCurrency} ${getDateFormat(new Date(deal.failedDealInfo.valueDateTimeQuoted))}`)
                }
            </TableCell>
            <TableCell>{deal.failedDealInfo.counterparty}</TableCell>
            <TableCell sx={{ width: '150px' }}>{deal.failReason}</TableCell>
            <TableCell>
                <ReprocessButton messageId={deal.messageId} />
            </TableCell>
        </TableRow>
    )
}

function ReprocessButton({ messageId }: { messageId: number }) {
    const [reprocessDeal, result] = useReprocessDeal();
    const [isLoading, setIsLoading] = useState(false);

    if (result) {
        if (result.isSuccess) {
            return (
                <Button color="success">
                    <CheckIcon />
                </Button>
            )
        }
    }

    return (
        <LoadingButton
            variant='contained'
            sx={{ margin: '0 auto' }}
            loading={isLoading}
            color={result?.isError ? 'error' : 'primary'}
            onClick={() => {
                setIsLoading(true);
                reprocessDeal(messageId)
                    .finally(() => setIsLoading(false));
            }}
        >
            <RestartAltIcon />
        </LoadingButton>
    )
}