import React, { useRef } from "react";
import { useState } from "react"

import { useRequest } from "../../shared/useRequest";
import { register } from "./usersSlice";

import './RegisterUser.css';

import { LoadingButton } from "@mui/lab";
import { TextField, FormGroup } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import { useAppDispatch } from "../../App/hooks";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { RIGHTS_FLAGS_DEFAULT, RightFlagsPicker } from "./RightFlagsPicker";


export function RegisterUser() {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [lastName, setLastName] = useState('');
    const rightsFlags = useRef('');


    const dispatch = useAppDispatch();
    const { sendRequest, isLoading, response, error } = useRequest(
        () => {
            return dispatch(register({ email, name, lastName, rightsFlags: rightsFlags.current }))
        }
    );

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        sendRequest();
        setEmail('');
        setName('');
        setLastName('');
        rightsFlags.current = RIGHTS_FLAGS_DEFAULT;
    }

    return (
        <>
            <h3>
                Регистрация брокера
            </h3>

            <form onSubmit={handleSubmit} className="RegisterUserForm">
                <TextField
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email"
                    label="Email"
                    required
                    margin="normal"
                    value={email}
                    onChange={event => setEmail(event.target.value)}
                />

                <FormGroup row>
                    <TextField
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Имя"
                        label="Имя"
                        required
                        margin="normal"
                        value={name}
                        onChange={event => setName(event.target.value)}
                    />

                    <TextField
                        type="text"
                        id="lastName"
                        name="lastName"
                        placeholder="Фамилия"
                        label="Фамилия"
                        required
                        margin="normal"
                        value={lastName}
                        onChange={event => setLastName(event.target.value)}
                    />
                </FormGroup>

                <RightFlagsPicker
                    onValuesChanged={(values: string) => {
                        rightsFlags.current = values;
                    }}
                />

                <LoadingButton
                    type='submit'

                    loading={isLoading}
                    loadingPosition="start"
                    startIcon={response && !error ? <DoneIcon /> : <SendIcon />}
                    variant="contained"
                    sx={{
                        margin: '15px'
                    }}
                >
                    Зарегистрировать
                </LoadingButton>

                <ErrorMessage text={`${error}`} />
            </form>
        </>
    )
}