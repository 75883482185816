import { Header } from "./Header";
import { Main } from "./Main";
import { Nav } from "./Nav";
import './Layout.css';

export function Layout() {
    return (
        <>
            <Header />
            <div className="main-container">
                <Nav />
                <Main />
            </div>
        </>
    )
}