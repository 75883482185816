import axios from "axios";
import api, { API_URL } from "../../shared/request";

const LOGIN_ENDPOINT = 'authentication/login';
export const REFRESH_ENDPOINT = 'authentication/refresh';
const CHANGE_PASSWORD_ENDPOINT = 'authentication/change-password';

export const loginRequest = async (email: string, password: string) => {
    return api.post(
        LOGIN_ENDPOINT,
        {
            email,
            password
        }
    )
        .then(res => {
            return res.data;
        })
        .catch(error => {
            throw (
                mapErrorCodeToRuMessage(error.response?.data?.errorCode)
            )
        })
}

export const checkAuthRequest = async () => {
    const axiosCheckAuthRequest = axios.create({
        withCredentials: true,
        baseURL: API_URL,
    });

    return axiosCheckAuthRequest.post(
        REFRESH_ENDPOINT
    )
        .then(res => {
            return res.data;
        })
        .catch(error => {
            throw (
                mapErrorCodeToRuMessage(error.response?.data?.errorCode)
            )
        })
}

function mapErrorCodeToRuMessage(errorCode: number) {
    switch (errorCode) {
        case 4011: 
            return 'Сессия истекла.';
        case 4041:
            return 'Пользователь с такими email и паролем не найден.';
        default:
            return 'Произошла ошибка.';
    }
}

const mapChangePasswordErrorCodeToRuMessage = (errorCode: number, errorDescription: string) => {
    switch (errorCode) {
        case 4003:
            return 'Слабый пароль.';
        case 4004:
            return "Пользователь не был зарегистрирован.";
        default:
            return errorDescription;
    }
}

export const changePasswordRequest = async function (password: string) {
    return api.post(CHANGE_PASSWORD_ENDPOINT,
        {
            'newPassword': password
        },
    )
        .then(response => response.data)
        .catch(e => {
            throw mapChangePasswordErrorCodeToRuMessage(
                e?.response?.data.errorCode,
                e?.response?.data.errorDescription
            );
        })
};