import api from "../../shared/request";
import { wrapFileIntoFormData } from "./helpers";

const INSTRUMENT_UPLOADING_ENDPOINT = '/dictionaries/InstrumentInfo/import';

function mapErrorToRuMessage(errorCode: number) {
    switch (errorCode) {
        case 4000:
            return "Неверный формат данных.";
        default:
            return "Что-то не так.";
    }
}

export const uploadInstrumentRequest = async function (fileToUpload: File) {
    const fileFormData = wrapFileIntoFormData(fileToUpload);

    return api.post(INSTRUMENT_UPLOADING_ENDPOINT, fileFormData,
        {
            headers: {
                'content-type': 'multipart/form-data',
            }
        })
        .then(res => {
            return res.data;
        })
        .catch(error => {
            throw mapErrorToRuMessage(error.response.data.errorCode);
        });
}