import { IconButton } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import UploadIcon from '@mui/icons-material/Upload';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useNavigate } from 'react-router';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAppDispatch } from '../App/hooks';
import { logout } from '../features/authentication/authSlice';

export function Nav() {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    return (
        <nav>
            <IconButton
                onClick={() => navigate('/brokers')}
                size='large'
            >
                <PersonIcon />
            </IconButton>

            <IconButton
                onClick={() => navigate('/instrumentinfoupload')}
                size='large'
            >
                <UploadIcon />
            </IconButton>

            <IconButton
                onClick={() => navigate('/restartdeals')}
                size='large'
            >
                <RestartAltIcon />
            </IconButton>

            <IconButton
                onClick={() => dispatch(logout())}
                size='large'
                sx={{
                    'margin-top': 'auto'
                }}
            >
                <LogoutIcon />
            </IconButton>
        </nav>
    )
}