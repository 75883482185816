import * as Yup from 'yup';
import { useRequest } from '../../shared/useRequest';
import { useAppDispatch } from '../../App/hooks';
import { changePassword } from '../authentication/authSlice';
import { useFormik } from 'formik';
import { Grid, IconButton, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import DoneIcon from '@mui/icons-material/Done';
import HelpIcon from '@mui/icons-material/Help';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import { PasswordInput } from '../../shared/PasswordInput';


const initialValues: { password: string, confirmPassword: string } = {
    password: '',
    confirmPassword: ''
}

const validationSchema = Yup.object({
    password: Yup.string()
        .required()
        .min(12, 'Пароль должен быть не менее 12 символов.')
        .test('DoesPasswordHaveUppercase',
            "Пароль должен содержать заглавную букву",
            password => password !== password.toLocaleLowerCase())
        .test('DoesPasswordHaveLowercase',
            'Пароль должен содержать строчную букву',
            password => password !== password.toUpperCase())
        .matches(/\d+/, 'Пароль должен содержать цифру.')
        .matches(/[!"#$%&()*+,-./:;<=>?@[\\ \]^_`{|}~']/,
            'Пароль должен содержать специальный символ.'),
    confirmPassword: Yup.string().required()
})

export const ChangePasswordForm = () => {
    const dispatch = useAppDispatch()
    const { sendRequest, isLoading, response, error } = useRequest(
        (password: string) => dispatch(changePassword({ password }))
    );

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: values => sendRequest(values.password)
    });

    return (
        <form
            style={{
                'display': 'flex',
                'flexDirection': 'column',
                'alignItems': 'flex-start',
                'width': '60%'
            }}
            onSubmit={formik.handleSubmit}
        >
            <Grid container spacing={2}>
                <Grid item xs={11}>
                    <PasswordInput
                        id="password"
                        name="password"
                        placeholder="Новый пароль"
                        required
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.password && !!formik.errors.password}
                        helperText={formik.touched.password ? formik.errors.password : null}

                        fullWidth
                        margin='normal'
                    />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip
                        style={{
                            width: 'fit-content',
                            marginTop: '20px'
                        }}

                        title="Пароль должен быть не короче 12 символов, иметь строчную и заглавную буквы, а также цифру и спец.символ."
                        placement="right"
                    >
                        <IconButton>
                            <HelpIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>


                <Grid item xs={11}>
                    <PasswordInput
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Подтверждение пароля"
                        required
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={!!formik.touched.confirmPassword && formik.values.password !== formik.values.confirmPassword}
                        helperText={!!formik.touched.confirmPassword && formik.values.password !== formik.values.confirmPassword
                            ? 'Пароли не совпадают.' : null}

                        fullWidth
                        margin='normal'
                    />
                </Grid>

                <Grid item xs={11}>
                    <LoadingButton
                        type='submit'
                        variant="contained"
                        fullWidth
                        endIcon={
                            !isLoading && response && !error
                                ? <DoneIcon />
                                : <SendIcon />
                        }
                        sx={{
                            margin: '40px 0',
                        }}
                        loading={isLoading}
                    >
                        Подтвердить
                    </LoadingButton>
                    <ErrorMessage
                        text={error.toString()}
                    />
                </Grid>
            </Grid>
        </form>
    )
}