import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import './LoginForm.css';
import { useState } from 'react';
import { useRequest } from '../../shared/useRequest';
import { useAppDispatch } from '../../App/hooks';
import { AuthCredentials, login } from './authSlice';
import { LoadingButton } from '@mui/lab';
import { ErrorMessage } from '../../shared/ErrorMessage/ErrorMessage';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { PasswordInput } from '../../shared/PasswordInput';

const initialValues: AuthCredentials = {
    email: '',
    password: '',
}

const validationSchema = Yup.object({
    email: Yup.string()
        .email('Неправильный email адрес.')
        .required('Введите email адрес.'),
    password: Yup.string()
        .required('Введите пароль.')
})

export function LogInForm() {
    const dispatch = useAppDispatch();
    const { sendRequest, isLoading, response, error } = useRequest(
        ({ email, password }: AuthCredentials) => dispatch(login({ email, password }))
    );

    const onSubmit = (values: AuthCredentials) => {
        sendRequest(values);
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    return (
        <form onSubmit={formik.handleSubmit}>
            <TextField
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                label="Email"
                fullWidth
                required
                margin="normal"
                value={formik.values.email}
                onChange={formik.handleChange}
            />
            {formik.errors.email ? <ErrorMessage text={formik.errors.email} /> : null}

            <PasswordInput
                id="password"
                name="password"
                placeholder="Пароль"
                label="Пароль"
                fullWidth
                required
                margin="normal"
                value={formik.values.password}
                onChange={formik.handleChange}
            />
            {formik.errors.password ? <ErrorMessage text={formik.errors.password} /> : null}

            <LoadingButton
                type='submit'
                loading={isLoading}
                variant='contained'
                fullWidth
            >
                Войти
            </LoadingButton>

            <ErrorMessage text={`${error}`} />

        </form>
    )
}